import { init, RematchDispatch, RematchRootState, Plugin } from '@rematch/core';
import createPersistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';
import { models, RootModel } from './models';

// 做永久化缓存，触发reducer会自动更新，可配置黑白名单
const persistPlugin: Plugin<RootModel> = createPersistPlugin({
  key: 'rematchState',
  storage,
  blacklist: ['phoneTool', 'historyTabs'],
});

export const store = init({
  models,
  plugins: [persistPlugin],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
