import { Models } from '@rematch/core';
import { app } from './app';
import { phoneTool } from './phoneTool';
import { historyTabs } from './historyTabs';

export interface RootModel extends Models<RootModel> {
  app: typeof app;
  phoneTool: typeof phoneTool;
  historyTabs: typeof historyTabs;
}

export const models: RootModel = { app, phoneTool, historyTabs };
